export enum QueryKeys {
  options = "NON-EXPIRED_OPTIONS",
  optionsWithType = "NON-EXPIRED_OPTIONS_WITH_TYPE",
  position = "POSITIONS",
  stake = "STAKED_CAPITAL",
  premia = "GET_PREMIA",
  tradeHistory = "TRADE_HISTORY",
  userBalance = "USER_BALANCE",
  topUserPoints = "TOP_USER_POINTS",
  userPoints = "USER_POINTS",
  braavosBonus = "BRAAVOS_BONUS",
  defiSpringClaimed = "DEFI_SPRING_CLAIMED",
  carmineStakes = "CARMINE_STAKES",
  airdropData = "AIRDROP_DATA",
  liveProposals = "LIVE_PROPOSALS",
  defispring = "DEFISPRING",
  tokenPrices = "TOKEN_PRICES",
  defispringApy = "DEFISPRING_APY",
}
